<template>
  <div id="user-top-mobile" class="hidden-lg hidden-md">
    <a href="/home" class="logo">
      <img src="https://static.phdvasia.com/mx/logos/logo_main.png"/>
    </a>
    <div class="box-right hidden-lg hidden-md">
      <div class="login-box">
        <dropdown menu-right>
          <btn class="dropdown-toggle inline-block" style="padding: 0 12px 0 12px !important;">
            <img src="https://static-mx.s3.amazonaws.com/icons/hamburger-icon.png" alt="Track Order" title="Track Order"
                  class="llamar-phone" width="20">
            <span class="caret" style="margin-left: 5px;"></span>
          </btn>
          <template slot="dropdown">
            <li>
              <a
                class="decoration-none"
                role="button"
                href="tel:5515153737"
                title="55 1515 3737">
                <img src="../../assets/images/icons/phone.svg"
                  class="llamar-phone" style="width: 15px !important;padding-right: 0.2em;">
                55 1515 3737
              </a>
            </li>
            <li>
              <a
                class="decoration-none"
                role="button"
                @click="showHideChat()">
                <img src="../../assets/images/icons/chat-icon.png"
                  class="llamar-phone" style="width: 15px !important;padding-right: 0.2em;">
                Chat
              </a>
            </li>
          </template>
        </dropdown>
      </div>

      <div class="login-box dropdown-lang" style="z-index: 990;">
        <dropdown menu-right>
          <btn class="dropdown-toggle">
            <img v-for="item in langList"
                 v-if="$const.LANGUAGES[item] && item ===$i18n.locale"
                 :src="$const.LANGUAGES[item]['image']"
                 class="xs-mr-5"
                 width='35'>
            <span class="caret"></span>
          </btn>
          <template slot="dropdown">
            <li v-for="item in langList">
              <a v-if="$const.LANGUAGES[item]"
                 role="button"
                 @click="changeLang(item)"
                 class="decoration-none">
                <img :src="$const.LANGUAGES[item]['image']"
                     class="xs-mr-5" width="30">
                {{ $const.LANGUAGES[item]['text'] }}
              </a>
            </li>
          </template>
        </dropdown>
      </div>
      <div class="login-box dropdown-lang" style="z-index: 990;">
        <dropdown menu-right>
          <btn class="dropdown-toggle dropdown-toggle-login">
            <img v-if="!$auth.check()" src="https://static.phdvasia.com/mx/icons/inactive@2x.png" class="mr-5" width="25">
            <img src="https://static.phdvasia.com/mx/icons/active@2x.png" class="mr-5" width="25" v-else>
            <span class="caret"></span></btn>
          <template slot="dropdown">
            <li v-if="$auth.check()">
              <router-link to="/profile" v-html="$t('full_name_format',
                {first_name:$auth.user().first_name, last_name:$auth.user().last_name})"></router-link>
            </li>
            <li v-if="!$auth.check()">
              <router-link to="/login">{{ $t("login_title")}}</router-link>
            </li>
            <li v-if="!$auth.check()">
              <router-link to="/register">{{ $t("login_register")}}</router-link>
            </li>
            <!-- <li>
              <router-link to="/tracking">{{ $t("track_your_order")}}</router-link>
            </li> -->
            <li v-if="$auth.check()">
              <a v-on:click="logout">{{ $t("button_logout")}}</a>
            </li>
          </template>
        </dropdown>
      </div>
    </div>

    <!--<div v-on:click="toggleLogin" class="login-logo pull-right">
      <img v-if="!$auth.check()" src="https://static.phdvasia.com/mx/icons/inactive@2x.png" width="25">
      <img src="https://static.phdvasia.com/mx/icons/active@2x.png" class="" width="25" v-else>
      <span class="pl-10">
          <img src="https://deo515rx9pz7c.cloudfront.net/fcds/icons/dropdown-black.png" width="10">
      </span>
    </div>
    <div class="login-box pull-right min-width-100" v-if="flag">
     &lt;!&ndash; <div class="clearfix pb2" v-if="flag"></div>&ndash;&gt;
      <div v-if="flag" class="item border-b line-h-30">
            <span v-if="$auth.check() ">
              {{$t('hello')}},  <router-link to="/profile">{{$auth.user().first_name}} {{$auth.user().last_name}}</router-link>
            </span>
        <router-link v-else to="/login">
          {{ $t("login_title") }}
        </router-link>
      </div>
<div class="item clearfix line-h-30" v-if="flag">
        <router-link to="/tracking">Track Order</router-link>
      </div>
      <div class="item clearfix border-b line-h-30" v-if="flag">
        <router-link to="/register" v-if="!$auth.check()">{{ $t("login_register")}}</router-link>
        <button v-else v-on:click="logout">{{ $t("button_logout")}}</button>
      </div>
    </div>-->
  </div>
</template>
<style scoped>
  .line-h-30 {
    line-height: 40px;
    padding-left: 10px;
  }

  .min-width-100 {
    min-width: 100px;
  }

  #user-top-mobile {
    position: absolute;
    right: 0;
    min-width: 80px;
    z-index: 1001;
  }

  .login-logo {
    text-align: center;
    /*line-height: 44px;*/
    width: 47px;
    margin: auto 10px auto auto;

  }

  .login-box {
    position: absolute;
    top: 44px;
    right: 0;
    width: 100px;
    line-height: 40px;
    background: #fff;
    /*padding: 0px 15px 0 0;*/
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 999;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    padding-left: 0px;
  }

  .login-box a, .login-box span, .login-box button {
    text-decoration: none;
    color: #333;
    font-weight: 600;
  }
</style>
<script>
import {
  mapGetters
} from 'vuex'

export default {
  mounted: function () {
    this.$cart.init(this)
    var vm = this
    this.$root.$on('cartUpdate', () => {
      vm.$cart.init(vm)
      vm.amount = vm.getTotalCartItem(vm.cart)
    })
    vm.amount = this.getTotalCartItem(this.cart)
  },
  destroyed () {
    this.$root.$off('cartUpdate')
  },
  data () {
    return {
      flag: false,
      amount: 0,
      languages: this.$const.LANGUAGES
    }
  },
  computed: {
    ...mapGetters({
      langList: 'langList'
    }),
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  methods: {
    toggleLogin () {
      this.flag = !this.flag
    },
    logout: function () {
      this.$auth.logout({
        data: {
          token: this.$auth.user().token
        },
        success: function (response) {
          if (response.data.status) {
            this.$router.push('/')
          } else {
            main.$notify({
              type: 'Error',
              title: response.data.error.msg
            })
          }
        },
        error: function (data) {
          main.$notify({
            type: 'Error',
            title: this.$t('message_logout_error')
          })
        }
      })
    },
    showHideChat() {
      window.showChat()
    },
    changeLang (la) {
      this.lang = la
      this.$store.dispatch('setLang', la)
      this.$session.set('lang', la)
      this.$router.go(this.$router.currentRoute)
    }
  }
}

</script>
